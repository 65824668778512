<template>
    <div class="block">
        <div class="flex flex-wrap">
            <div v-for="(item, n) in data" :key="n" class="block sm:w-1/3 md:w-1/4 w-1/3 mb-2">
                <div @click="onClick(item)" :class="[(n + 1) % (windowWidth < 600 ? 3 : 4) === 0 ? '' : n !== 0 ? 'mr-2' : 'mr-2']">
                    <div class="w-full rounded-t-lg overflow-hidden" style="height: 150px">
                        <b-image :src="item.Image" class="w-full h-full">
                            <b-skeleton class="skeleton-placeholder" slot="placeholder" height="100%"></b-skeleton>
                        </b-image>
                    </div>
                    <div class="w-3/4 px-3">
                        <span class="sm:text-sm md:text-lg text-sm font-bold">{{ item.Item }}</span>
                        <span class="sm:text-sm md:text-base text-sm flex">{{ item.CurrencyCode }} {{ item.Total }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Array
        }
    },
    methods: {
        onClick (item) {
            console.log(item)
            this.$router.push('/' + item.DefaultAction.Url)
        }
    }
}
</script>
<style lang="scss" scoped>
    ::v-deep .b-skeleton {
        height: 100%;
        position: absolute;
        top: 0;
    }
    ::v-deep img {
        height: 100%;
    }
</style>
